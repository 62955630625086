export const getAllEnv = () => {
  return {
    ...process.env,
    ...window.env,
  };
};

export const getEnv = (envName, { isBoolean } = {}) => {
  const value = getAllEnv()[envName];
  if (isBoolean) return value === true || value === 'true';
  return value;
};

export const getIsDevelopment = () => getEnv('REACT_APP_ENV') === 'development';

export const getBaseUrl = () => {
  const DEV_BASE_URL = getEnv('REACT_APP_DEV_BASE_URL');
  const LIVE_BASE_URL = getEnv('REACT_APP_LIVE_BASE_URL');
  return getIsDevelopment() ? DEV_BASE_URL : LIVE_BASE_URL;
};

export const getMailerMicroserviceBaseUrl = () =>
  getEnv('REACT_APP_MAILER_MICROSERVICE_BASE_URL');

export const getPublicUrl = () => getEnv('PUBLIC_URL');
