import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import 'assets/css/flexboxgrid.css';
import 'assets/css/react-select.css';
import 'react-step-progress-bar/styles.css';
import WithEnvLoaded from 'WithEnvLoaded';

ReactDOM.render(<WithEnvLoaded />, document.getElementById('root'));

serviceWorker.unregister();
