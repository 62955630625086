export const UNSUBSCRIPTION_PARAM = 'ui';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

/* Using different keys based on the site's hostname
 * eg localhost / mail.turing.com /
 */

export const USER_TOKEN_KEY = `admin_token_${window.location.hostname}`;

export const LOGGED_USER_KEY = `user_details_${window.location.hostname}`;

export const USER_2FA_VERIFIED_KEY = `admin_2fa_verified_${window.location.hostname}`;

export const API_KEYS_TOKEN_KEY = `default_project_api_key_${window.location.hostname}`;

export const MIN_INPUT_LENGTH = 3;

export const MIN_LARGE_INPUT_LENGTH = 8;

export const MAX_RECIPIENTS_IN_EMAIL_REQUEST = 900;

export const MAX_SENT_EMAIL_FOR_ORG_UNSUBSCRIPTION = 1000;

export const EMAIL_SERVICES = {
  SENDGRID: 'SendGrid',
  SENDINBLUE: 'SendInBlue',
  // SPARKPOST: 'SparkPost',
  GMAIL: 'Gmail accounts',
  MICROSOFT: 'Microsoft',
  // POSTMARK: 'PostMark',
  // MAILGUN: 'MailGun',
  // ELASTIC_EMAIL: 'ElasticEmail',
  // SENDPULSE: 'SendPulse',
  // MAILERSEND: 'MailerSend',
  // SMTPDOTCOM: 'SMTP.com',
};

export const SEND_THROUGH_LIST = Object.values(EMAIL_SERVICES);

export const EMAIL_EVENTS = [
  'unsubscribe',
  'resubscribe',
  'open',
  'click',
  'bounce',
  'spam',
  'failure',
  'delivered',
  'survey_submission',
  'typeform_response',
];

export const DEFAULT_USER_ROLE = 'regular';

export const DEFAULT_ACCESS_CONTROL = [
  'profile.view',
  'recommendations.view',
  'unsubscription.view',
  'mail.projects.view',
];

export const EMAIL_TYPE_OPTIONS = [
  {
    name: 'marketing',
    id: 1,
  },
  { name: 'transactional', id: 2 },
  { name: 'growth', id: 3 },
  { name: 'outbound', id: 4 },
];
