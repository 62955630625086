import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';
import { getEnv, getIsDevelopment, getPublicUrl } from 'services/env-service';
import { BrowserTracing } from '@sentry/tracing';
import loadable from '@loadable/component';
import { setUserToken } from 'services/token';
import { hot } from 'react-hot-loader';

const AppWithProvider = loadable(() => import('./AppWithProvider'));

function WithEnvLoaded() {
  const [envLoaded, setEnvLoaded] = useState(false);
  const [magicLinkChecked, setMagicLinkChecked] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const authValue = searchParams.get('auth');
    if (authValue) {
      setUserToken(authValue);
      searchParams.delete('auth');
      url.search = searchParams.toString();
      window.history.replaceState({}, document.title, url.toString());
    }
    setMagicLinkChecked(true);
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${getPublicUrl()}/env.js?q=${Date.now()}`;
    script.async = true;

    script.onload = () => {
      if (!getIsDevelopment()) {
        Sentry.init({
          dsn: getEnv('REACT_APP_SENTRY_DSN'),
          environment: getEnv('REACT_APP_SENTRY_ENV'),
          integrations: [new BrowserTracing()],
          tracesSampleRate: 0.5,
        });
      }
      setEnvLoaded(true);
    };

    script.onerror = () => {
      captureException(new Error('Error loading env.js'));
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!envLoaded || !magicLinkChecked) {
    return <></>;
  }

  return <AppWithProvider />;
}

export default process.env.NODE_ENV === 'development'
  ? hot(module)(WithEnvLoaded)
  : WithEnvLoaded;
