import {
  USER_TOKEN_KEY,
  USER_2FA_VERIFIED_KEY,
  LOGGED_USER_KEY,
} from 'constants/common';

export const getUserToken = () => localStorage.getItem(USER_TOKEN_KEY);

export const setUserToken = val =>
  localStorage.setItem(USER_TOKEN_KEY, (val || '').toString());

export const clearUserToken = () => setUserToken('');

export const removeUserToken = clearUserToken;

export const setUser2FAStatus = val =>
  localStorage.setItem(USER_2FA_VERIFIED_KEY, (val || '').toString());

export const getUser2FAStatus = () =>
  localStorage.getItem(USER_2FA_VERIFIED_KEY);

export const clearUser2FAStatus = () => setUser2FAStatus('');

export const checkToken = () => {
  const token = getUserToken();
  let valid = true;
  if (token && token.split('.').length && token.split('.')[1]) {
    try {
      const { exp } = JSON.parse(atob(token.split('.')[1]));
      if (Date.now() >= exp * 1000) {
        valid = false;
      }
    } catch (e) {
      valid = false;
    }
  } else {
    valid = false;
  }
  return valid;
};

export const setUser = user =>
  localStorage.setItem(LOGGED_USER_KEY, JSON.stringify(user));

export const getUser = () => {
  return localStorage.getItem(LOGGED_USER_KEY) || `{"name":"admin"}`;
};

export const removeUser = () => localStorage.setItem(LOGGED_USER_KEY, '');
